import Head from 'next/head'

import React, { ReactNode, useEffect } from 'react'

import {
  getPageContent,
  IPageFields,
  getAds,
  getGlobalSettings
} from 'midi-city-ui'

import { AppMachine } from 'midi-city-app-manager'
import { TypedDocumentNode } from 'packages/sanity'

import { GetStaticProps } from 'next'

export interface PageProps extends StaticProps {
  appMachine: AppMachine.Interpreter
  children?: ReactNode
}

function Index({
  appMachine,
  pageFields,
  ads,
  globalSettings
}: PageProps): JSX.Element {
  return (
    <Content
      pageFields={pageFields}
      appMachine={appMachine}
      globalSettings={globalSettings}
      ads={ads}
    />
  )
}

export function Content({
  pageFields,
  appMachine,
  ads,
  globalSettings,
  children
}: PageProps): JSX.Element {
  useEffect(() => {
    appMachine.send({ type: 'ADS_UPDATE', ads })
  }, [appMachine, ads])

  useEffect(() => {
    appMachine.send({ type: 'GLOBAL_SETTINGS_UPDATE', globalSettings })
  }, [appMachine, globalSettings])

  return (
    <>
      <Head key="about">
        <meta name="description" content={pageFields?.metaDescription} />
      </Head>
      {children}
    </>
  )
}

export interface StaticProps {
  pageFields: IPageFields | null
  ads: TypedDocumentNode.AdsQuery['allAd']
  globalSettings: TypedDocumentNode.GlobalSettingsQuery
}

export const getStaticProps: GetStaticProps = async _context => {
  const pageFields = await getPageContent('/')
  const ads = await getAds()
  const globalSettings = await getGlobalSettings()
  const props: StaticProps = {
    pageFields: pageFields ?? null,
    ads,
    globalSettings
  }
  return { props, revalidate: 60 }
}

export default Index
